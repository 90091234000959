/* global grecaptcha */
import 'regenerator-runtime/runtime'; // Allows async functions
import 'lazysizes';
import 'lazysizes/plugins/respimg/ls.respimg';
import 'lazysizes/plugins/bgset/ls.bgset';
import MoveTo from 'moveto/src/moveTo.js';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { tns } from 'tiny-slider/src/tiny-slider.js';
import ApexCharts from 'apexcharts';
import 'cloud9carousel/jquery.cloud9carousel.js';
import $ from 'jquery';
import Player from '@vimeo/player';
import * as PIXI from 'pixi.js';
import lottie from 'lottie-web';

PIXI.utils.skipHello();

const easeFunctions = {
    easeInOutQuad: function (t, b, c, d) {
        t /= d / 2;
        if (t < 1) return c / 2 * t * t + b;
        t--;
        return -c / 2 * (t * (t - 2) - 1) + b;
    }
};

function debounce(func, wait, immediate) {
    let timeout;
    return function () {
        let context = this, args = arguments;
        let later = () => {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        let callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
}

function throttle(callback, limit) {
    let wait = false;
    return function () {
        if (!wait) {
            callback.call();
            wait = true;
            setTimeout(() => {
                wait = false;
            }, limit);
        }
    };
}

function checkMobileSize() {
    return window.matchMedia('(max-width: 740px)').matches;
}

function checkMobile() {
    var check = function (a) {
        return (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4)));
    };
    return check(navigator.userAgent || navigator.vendor || window.opera);
}

function getSiblings(elem) {
    const siblings = [];
    let sibling = elem.parentNode.firstChild;
    while (sibling) {
        if (sibling.nodeType === 1 && sibling !== elem) {
            siblings.push(sibling);
        }
        sibling = sibling.nextSibling;
    }
    return siblings;
}

function onMousemove(e, obj, container) {
    let m_posx = 0,
        m_posy = 0,
        e_posx = 0,
        e_posy = 0;
    const top = parseFloat(window.getComputedStyle(container).paddingTop);

    if (!e) {
        e = window.event;
    }
    if (e.pageX || e.pageY) {
        m_posx = e.pageX;
        m_posy = e.pageY;
    } else if (e.clientX || e.clientY) {
        m_posx = e.clientX + document.body.scrollLeft + document.documentElement.scrollLeft;
        m_posy = e.clientY + document.body.scrollTop + document.documentElement.scrollTop;
    }
    if (obj.offsetParent) {
        do {
            e_posx += obj.offsetLeft;
            e_posy += obj.offsetTop;
        } while (obj === obj.offsetParent);
    }
    m_posy -= top; // padding top on .polarize-grid
    return { offsetX: (m_posx - e_posx), offsetY: (m_posy - e_posy) };
}

function numberWithCommas(n) {
    const parts = n.toString().split('.');
    return parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',') + (parts[1] ? '.' + parts[1] : '');
}

function countUp(target) {
    if (target.classList.contains('active')) {
        return;
    }

    const options = {
        target: target,
        from: 0,
        to: parseFloat(target.getAttribute('data-countup')),
        speed: 800,
        refreshInterval: 60
    };

    const updateTimer = () => {
        value += increment;
        loopCount++;
        if (options.target.classList.contains('countup_no_comma')) {
            options.target.innerHTML = value.toFixed(0);
        } else {
            options.target.innerHTML = numberWithCommas(value.toFixed(0));
        }
        if (loopCount >= loops) {
            clearInterval(interval);
        }
    };
    let loops = Math.ceil(options.speed / options.refreshInterval),
        increment = (options.to - options.from) / loops,
        loopCount = 0,
        value = options.from,
        interval = setInterval(updateTimer, options.refreshInterval);

    target.classList.add('active');
}

async function renderCountUp() {
    const targets = document.querySelectorAll('section.stats .cke-title, section#specifications .cke-title');

    for (const string of targets) {
        let number = string.textContent.match(/^[0-9.,]+/gm);

        if (number !== null) {
            if (number[0].match(/,/g) == null) {
                number = parseFloat(number[0].replace(/,/g, ''));
                string.innerHTML = string.textContent.replace(/^[0-9.,]+/gm, `<span class="countup countup_no_comma" data-countup="${number}">0</span>`);
            } else {
                number = parseFloat(number[0].replace(/,/g, ''));
                string.innerHTML = string.textContent.replace(/^[0-9.,]+/gm, `<span class="countup" data-countup="`+number+`">0</span>`);
            }
        }
    }

    const countup_targets = document.querySelectorAll('span.countup');

    const options = {
        root: null,
        rootMargin: '0% 0% -20% 0%',
        threshold: 0
    };

    const handleIntersect = (entries, observer) => {
        for (const entry of entries) {
            if (entry.isIntersecting) {
                setTimeout(() => {

                    countUp(entry.target);

                    observer.unobserve(entry.target);
                }, 1);
            }

        }
    };

    const observer = new IntersectionObserver(handleIntersect, options);

    for (const item of countup_targets) {
        observer.observe(item);
    }
}

async function scrollFadeIn() {
    const sf_containers = document.querySelectorAll('.scroll-fadein');
    const selectors = '.sf-item, .sf-item-opacity, .title, .cke-title, .cke-small-title, .cke-header, .cke-plusses-hr, .rich-text p, .rich-text img, .rich-text .img:not(.grayscale), .rich-text .caption, .input--outline, .btn--select, .quick-navs, .signup, .divider, .contact_form .info';
    const viewport_height = window.pageYOffset + window.innerHeight;
    let is_first = true;

    for (const item of sf_containers) {
        const targets = item.querySelectorAll(selectors);
        for (const [index, target] of targets.entries()) {
            target.style.transitionDelay = `${index * .12}s`;

            if (viewport_height >= target.getBoundingClientRect().top && is_first) {
                is_first = false;
                requestAnimationFrame(() => {
                    target.closest('.scroll-fadein').classList.add('scroll-fadein--active');
                });
            }
        }
    }

    const options = {
        root: null,
        rootMargin: '0% 0% -60% 0%',
        threshold: 0
    };

    const handleIntersect = (entries, observer) => {
        for (const entry of entries) {
            if (entry.isIntersecting) {
                requestAnimationFrame(() => {
                    entry.target.classList.add('scroll-fadein--active');
                    observer.unobserve(entry.target);
                });
            }
        }
    };

    const observer = new IntersectionObserver(handleIntersect, options);

    for (const item of sf_containers) {
        observer.observe(item);
    }
}

function openMenu(settings) {
    const hamburger = settings['header'].querySelectorAll('.hamburger');
    const logo = settings['header'].querySelectorAll('.logo');
    const page_nav_btn = settings['header'].querySelector('.page-nav-btn');
    const frequency = 1000;
    let brand_word_length = 0;
    let rotator;

    const clickToClose = (e) => {
        if (!e.target.closest('header')) {
            settings['header'].classList.remove('menu_open');
            document.removeEventListener('click', clickToClose);
        }
    };

    const showHideMenu = (e) => {
        if (settings['header'].classList.contains('menu_open')) {
            if (!settings['header'].classList.contains('mobile_nav_open')) {
                //settings['html'].classList.remove('scroll-lock');
                // needed for iOS 12
                enableBodyScroll(settings['header'].querySelector('.menu .scroll'));
            }

            settings['header'].classList.remove('menu_open');

            if (!e.target.closest('.head')) {
                setTimeout(() => {
                    settings['header'].classList.remove('shift_zindex');
                }, 200);
            } else {
                setTimeout(() => {
                    settings['header'].classList.remove('shift_zindex_alt');
                }, 200);
            }
        } else {
            //settings['html'].classList.add('scroll-lock');
            // needed for iOS 12
            disableBodyScroll(settings['header'].querySelector('.menu .scroll'));
            settings['header'].classList.add('menu_open');

            if (!e.target.closest('.head')) {
                settings['header'].classList.add('shift_zindex');
            } else {
                settings['header'].classList.add('shift_zindex_alt');
            }
        }
        document.addEventListener('click', clickToClose);
    };

    let pickword_intervals;
    let pickword_paused = false;

    const pickWord = (settings) => {
        if (checkMobileSize() || pickword_paused) return;

        const words_slide = settings['logo'].querySelector('.words-slide');
        const words_hide = settings['logo'].querySelector('.words-hide');
        const word_group = words_slide.querySelector('.words-group');
        let word_group_clone = word_group.cloneNode(true);
        const word_length = words_slide.querySelectorAll('.words-group:first-child div').length;
        const underline = settings['logo'].querySelector('.underline');
        let distance = 0;
        let index = 1;

        if (settings['event'] === 'mouseleave') {
            pickword_paused = true;
            settings['logo'].classList.remove('hover');
            clearInterval(pickword_intervals);
            underline.style.width = ``;
            words_hide.style.transition = `transform .4s`;
            words_hide.style.transform = `translate3d(0,30px,0)`;

            setTimeout(() => {
                words_hide.style.transition = ``;
                words_hide.style.transform = ``;
                words_slide.style.transition = ``;
                words_slide.style.transform = ``;
                distance = 0;
                index = 1;
                pickword_paused = false;
            }, 200);
            return;
        } else {
            settings['logo'].classList.add('hover');
            words_slide.style.transition = `transform .4s`;
        }

        const animation = () => {
            const active_word = word_group.querySelector(`div:nth-child(${index})`);
            underline.style.width = `${active_word.offsetWidth - 5}px`;

            if (word_length === index) {
                word_group_clone = word_group.cloneNode(true);
                words_slide.appendChild(word_group_clone);
            }

            words_slide.style.transform = `translate3d(0,-${distance}px,0)`;
            distance = distance + 30;
            index = index === word_length ? 1 : index + 1;
        };

        animation();

        pickword_intervals = setInterval(() => {
            animation();
        }, 900);
    };

    for (const h of hamburger) {
        h.addEventListener('click', (e) => {
            showHideMenu(e);
        });
    }

    for (const l of logo) {
        l.addEventListener('mouseenter', () => {
            pickWord({
                'event': 'mouseenter',
                'logo': l
            });
        });

        l.addEventListener('mouseleave', () => {
            pickWord({
                'event': 'mouseleave',
                'logo': l
            });
        });
    }

    page_nav_btn.addEventListener('click', () => {
        if (settings['header'].classList.contains('mobile_nav_open')) {
            //settings['html'].classList.remove('scroll-lock');
            // needed for iOS 12
            enableBodyScroll(settings['header'].querySelector('.page-nav-mobile'));
            settings['header'].classList.remove('mobile_nav_open');
        } else {
            //settings['html'].classList.add('scroll-lock');
            // needed for iOS 12
            disableBodyScroll(settings['header'].querySelector('.page-nav-mobile'));
            settings['header'].classList.add('mobile_nav_open');
        }
    });

    document.addEventListener('keydown', (e) => {
        if (e.code === 'Escape') {
            settings['header'].classList.remove('menu_open');
        }
    });
}

async function pageNav() {
    const html = document.querySelector('html');
    const header = document.querySelector('header');
    const pagenav = header.querySelector('.page-nav');
    const pagenav_links = pagenav.querySelectorAll('.link');
    const links = header.querySelectorAll('.page-nav .link, .page-nav-mobile .link');
    const page_nav_btn_text = header.querySelector('.page-nav-btn > div');
    const has_progressbar = !pagenav.classList.contains('alt');
    let prev_nav_text;

    let link_array = [];
    let scrolling = false;
    let last_scroll_pos = 0;

    if (has_progressbar) {
        for (const link of pagenav_links) {
            const progress_bar = link.querySelector('.progress-bar');
            const hash = progress_bar.querySelector('a').getAttribute('href');
            const section = document.querySelector(hash);

            if (!section) return;
            const last_section = section.nextElementSibling === null;

            link_array.push({ 'progress_bar': progress_bar, 'section': section, 'section_title': link.getAttribute('data-title').trim(), 'last_section': last_section });
        }

        for (const link of links) {
            const progress_bar = link.querySelector('.progress-bar');
            const hash = progress_bar.querySelector('a').getAttribute('href');
            const section = document.querySelector(hash);

            for (const anchor of link.querySelectorAll('a')) {
                anchor.addEventListener('click', (e) => {
                    e.preventDefault();
                    scrolling = true;
                    //html.classList.remove('scroll-lock');
                    // needed for iOS 12
                    enableBodyScroll(header.querySelector('.page-nav-mobile'));
                    header.classList.remove('mobile_nav_open', 'hover');

                    new MoveTo({
                        tolerance: 0,
                        duration: 400,
                        easing: 'easeInOutQuad',
                        container: window
                    }, easeFunctions).move(section);

                    setTimeout(() => {
                        scrolling = false;
                    }, 500);
                });
            }
        }
    }

    const getLinkProgress = () => {
        const scroll_pos = document.documentElement.scrollTop || document.body.scrollTop;
        const scroll_height = document.documentElement.scrollHeight - window.innerHeight;

        for (const [index, object] of link_array.entries()) {
            const section_offset = link_array[index]['section'].getBoundingClientRect().top * -1;
            let section_next_offset;
            let scroll_offset;
            let width;

            if (link_array[index + 1] !== undefined) {
                section_next_offset = link_array[index + 1]['section'].getBoundingClientRect().top + scroll_pos;
                scroll_offset = scroll_pos - section_offset;
                width = (section_offset / (section_next_offset - scroll_offset)) * 100;
            } else {
                scroll_offset = link_array[index]['section'].getBoundingClientRect().top + scroll_pos;
                width = (section_offset / (scroll_height - scroll_offset)) * 100;
            }

            if (width >= 0 && width < 100) {
                object['progress_bar'].style.width = `${width}%`;
                if (checkMobileSize()) {
                    if (prev_nav_text !== link_array[index]['section_title']) {
                        page_nav_btn_text.textContent = link_array[index]['section_title'];
                    }
                    prev_nav_text = link_array[index]['section_title'];
                }
            } else if (width < 0) {
                object['progress_bar'].style.width = `0%`;
            } else if (width >= 100) {
                object['progress_bar'].style.width = `100%`;
            }
        }

        if (!scrolling) {
            if (header.classList.contains('menu_open')) return;

            if (scroll_pos <= 50) {
                if (header.classList.contains('hover')) {
                    header.classList.remove('hover');
                }
                return;
            }

            if (scroll_pos >= last_scroll_pos) {
                if (header.classList.contains('hover')) {
                    header.classList.remove('hover');
                }
            } else {
                if (!header.classList.contains('hover')) {
                    header.classList.add('hover');
                }
            }
        }

        last_scroll_pos = scroll_pos;
    };

    getLinkProgress();

    window.addEventListener('scroll', throttle(() => {
        getLinkProgress();
    }, 10));

    const hover = (value) => {
        if (checkMobileSize()) return;

        if (!scrolling) {
            if (value === 'enter') {
                header.classList.add('hover');
            }
            if (value === 'leave' && !header.classList.contains('menu_open')) {
                header.classList.remove('hover');
            }
        }
    };

    pagenav.addEventListener('mouseenter', () => hover('enter'));
    pagenav.addEventListener('mouseleave', () => hover('leave'));

    for (const elem of header.querySelectorAll('.head .hamburger, .head .logo, .head .page-nav-btn')) {
        elem.addEventListener('mouseenter', () => hover('enter'));
        elem.addEventListener('mouseleave', () => hover('enter'));
    }

    openMenu({
        'header': header,
        'html': html
    });
}

async function carouselImages() {
    const slider_elem = document.querySelector('section.carousel-images');

    if (!slider_elem) return;

    const item_count = parseInt(slider_elem.getAttribute('data-items-displayed'));
    const numberItems = parseInt(slider_elem.getAttribute('data-number-items'));

    tns({
        container: 'section.carousel-images .slider',
        items: item_count || 1,
        gutter: 30,
        slideBy: 'page',
        loop: numberItems > 1,
        controls: numberItems > 1,
        nav: false,
        mouseDrag: true,
        touch: true,
        swipeAngle: 35,
        speed: 400,
        autoplayTimeout: 0,
        preventActionWhenRunning: true,
        onInit: function () { },
        responsive: {
            300: {
                items: 1,
            },
            750: {
                items: item_count,
            }
        }
    });
}

async function carouselImageOverlay() {
    const slider_elems = document.querySelectorAll('section.carousel-image-overlay');
    if (!slider_elems.length) return;

    for (let i = 0; i < slider_elems.length; i++) {
        let options = {
            container: `section.carousel-image-overlay.block-${slider_elems[i].getAttribute('data-block')} .slider`,
            items: 1,
            gutter: 0,
            slideBy: 'page',
            loop: true,
            controls: true,
            nav: false,
            mouseDrag: true,
            touch: true,
            swipeAngle: 35,
            speed: 400,
            autoplayTimeout: 0,
            preventActionWhenRunning: true,
            fixedWidth: window.innerWidth > 750 ? window.innerWidth - 100 : window.innerWidth,
            onInit: function (e) {
                setTimeout(() => {
                    e.container.classList.add('tns-animate');
                }, 100);
            },
        };

        let slider = tns(options);

        slider.events.on('transitionStart', (e) => {
            e.container.classList.add('tns-animate');
            setTimeout(() => {
                e.container.classList.remove('tns-animate');
            }, 400);
        });

        window.addEventListener('resize', debounce(() => {
            let width = window.innerWidth;
            if (window.innerWidth > 750) width -= 100;
            options.fixedWidth = width;
            slider.destroy();
            slider = tns(options);
        }, 200));
    }
}

async function visionTimeline() {
    const timeline = document.querySelector('section.vision-timeline:not(.expand)');
    const timeline_expand = document.querySelector('section.vision-timeline.expand');
    if (!timeline) return;

    function eventPopups(index, value) {
        let btn = value.querySelector('.btn'),
            day = value.getAttribute('data-day'),
            month = value.getAttribute('data-month'),
            year = value.getAttribute('data-year'),
            text = value.getAttribute('data-text'),
            image = value.getAttribute('data-image'),
            timelineEntries = $('.timeline-event');

        value.style.zIndex = timeline.querySelectorAll('.timeline-event').length - index;

        switch (month) {
            case '00':
                month = '';
                break;
            case '01':
                month = 'Jan';
                break;
            case '02':
                month = 'Feb';
                break;
            case '03':
                month = 'Mar';
                break;
            case '04':
                month = 'Apr';
                break;
            case '05':
                month = 'May';
                break;
            case '06':
                month = 'Jun';
                break;
            case '07':
                month = 'Jul';
                break;
            case '08':
                month = 'Aug';
                break;
            case '09':
                month = 'Sep.';
                break;
            case '10':
                month = 'Oct';
                break;
            case '11':
                month = 'Nov';
                break;
            case '12':
                month = 'Dec';
                break;
        }

        btn.insertAdjacentHTML('beforeend', `
        <div class="popup">
            ${image ? `<div class="img"><div class="lazyload" data-bgset="${image}"></div></div>` : ''}
            <div>
                <div class="date">${month}${day === 'None' ? '' : ` ${day}`}${day !== 'None' || month ? ', ' : ''}${year}</div>
                <div class="text">${text}</div>
            </div>
        </div>
        `);

        function enter(value) {
            timelineEntries.removeClass('active');
            value.classList.add('active');
        }

        function leave() {
            timelineEntries.removeClass('active');
        }

        value.addEventListener('mouseenter', () => enter(value));
        value.addEventListener('mouseleave', () => leave());
    }

    for (let [index, value] of timeline.querySelectorAll('.timeline-event').entries()) {
        eventPopups(index, value);
    }

    if (!timeline_expand) return;

    const resize_infobox = () => {
        const timeline_expand_info = timeline_expand.querySelector('.info');
        const pattern_height = 50;

        timeline_expand_info.style.height = ``;

        let height = timeline_expand_info.offsetHeight;

        if (height % pattern_height !== 0) {
            const height_rounded = Math.round(height/pattern_height)*pattern_height;

            if (height_rounded < height && (height-height_rounded) > (pattern_height/4)) {
                height = height_rounded + pattern_height;
            } else {
                height = height_rounded;
            }

            timeline_expand_info.style.height = `${height}px`;
        }
    };

    resize_infobox();

    window.addEventListener('resize', debounce(() => {
        resize_infobox();
    }, 200));
}

async function carouselBlock() {
    const slider_elems = document.querySelectorAll('section.carousel-block');
    if (!slider_elems.length) return;

    for (let i = 0; i < slider_elems.length; i++) {
        const slider_elem = slider_elems[i];
        const chart_elem = slider_elem.querySelector('.chart');

        const series = [];
        const labels = [];
        const colors = [];
        let chart_json;

        if (chart_elem) {
            chart_json = JSON.parse(chart_elem.getAttribute('data-chart-json'));

            for (const item of chart_json.data) {
                series.push(Number(item[0]));
                colors.push(`#${item[1]}`);
                labels.push(item[2]);
            }
        }

        const options = {
            series: series,
            labels: labels,
            colors: colors,
            chart: {
                width: '100%',
                height: 'auto',
                type: 'donut',
                selection: {
                    enabled: false,
                },
            },
            plotOptions: {
                pie: {
                    donut: {
                        size: '54%',
                    },
                    customScale: 1.24,
                    offsetY: 50,
                    expandOnClick: false,
                }
            },
            states: {
                hover: {
                    filter: {
                        type: 'none',
                        value: 0,
                    }
                },
                active: {
                    filter: {
                        type: 'none',
                        value: 0,
                    }
                },
            },
            tooltip: {
                enabled: false,
            },
            stroke: {
                show: false,
                width: 0,
            },
            dataLabels: {
                style: {
                    fontSize: '12px',
                    fontFamily: 'Akkurat-Mono',
                    colors: ['#000000', '#FFFFFF', '#000000', '#000000'],
                },
                dropShadow: {
                    enabled: false,
                }
            },
            legend: {
                show: true,
                fontSize: '20px',
                fontFamily: 'Akkurat-Mono',
                floating: true,
                position: 'bottom',
                horizontalAlign: 'left',
                verticalAlign: 'middle',
                offsetY: -50,
                markers: {
                    width: 14,
                    height: 14,
                    strokeWidth: 0,
                    radius: 0,
                },
            },
        };
        const chart = new ApexCharts(chart_elem, options);

        const item_count = parseInt(slider_elem.getAttribute('data-items-displayed'));
        tns({
            container: slider_elem.querySelector('.slider'),
            items: item_count,
            slideBy: 'page',
            loop: false,
            controls: true,
            nav: false,
            mouseDrag: true,
            touch: true,
            swipeAngle: 35,
            speed: 400,
            autoplayTimeout: 0,
            preventActionWhenRunning: true,
            onInit: () => chart_json && chart_json.data.length ? chart.render() : '',
            responsive: {
                300: {
                    items: 1,
                },
                750: {
                    items: item_count,
                }
            }
        });
    }
}

async function carouselSolid() {
    const slider_elem = document.querySelectorAll('section.carousel-solid .item');

    if (slider_elem.length <= 1) return;

    tns({
        container: 'section.carousel-solid .slider',
        items: 1,
        slideBy: 'page',
        loop: true,
        controls: true,
        nav: false,
        mouseDrag: true,
        touch: true,
        swipeAngle: 35,
        speed: 400,
        edgePadding: 0,
        autoplayTimeout: 0,
        preventActionWhenRunning: true,
        onInit: function () { },
    });
}

async function visionProgramSlider() {
    const slider_elem = document.querySelector('section.vision-programs-carousel');

    if (!slider_elem) return;

    tns({
        container: 'section.vision-programs-carousel .slider',
        items: 1,
        slideBy: 1,
        loop: true,
        controls: true,
        nav: false,
        mouseDrag: true,
        touch: true,
        swipeAngle: 35,
        speed: 400,
        autoplayTimeout: 0,
        preventActionWhenRunning: true,
        onInit: () => slider_elem.querySelector('.slider').style.display = 'block', // fix for carousel not working on Edge
        responsive: {
            750: {
                items: 3,
                edgePadding: 30,
            }
        }
    });
}

function modalFrame(item) {
    const type = item.getAttribute('data-video_type');
    const id = item.getAttribute('data-video_id');
    let url;

    if (type === 'vimeo') {
        url = `https://player.vimeo.com/video/${id}?autoplay=1`;
    } else if (type === 'youtube') {
        url = `https://www.youtube.com/embed/${id}?autoplay=1`;
    } else if (type === 'iframe') {
        url = id;
    }

    if (id) {
        const body = document.querySelector('body');
        body.classList.add('modal-active');
        body.insertAdjacentHTML('beforeend', `
            <div class="modal iframe">
                <div class="modal-container">
                    <div class="modal-close" role="button">Close</div>
                    <div class="modal-content-wrap">
                        <iframe src="${url}" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
                    </div>
                </div>
            </div>
            `);

        document.querySelector('.modal-close').addEventListener('click', () => {
            const model = document.querySelector('body > .modal');
            body.classList.remove('modal-active');
            model.parentNode.removeChild(model);
        });
    }
}

async function renderModalFrame() {
    const modal_frame_elems = document.querySelectorAll('.modal-frame');

    if (!modal_frame_elems) return;

    for (const elem of modal_frame_elems) {
        elem.addEventListener('click', () => {
            modalFrame(elem);
        });
    }
}

async function vision3dCarousel() {
    const slider_elem = document.querySelector('section.vision-circular-carousel');

    if (!slider_elem) return;

    const carousel = slider_elem.querySelector('.carousel');
    const cloud9_items = slider_elem.querySelectorAll('.cloud9-item');
    const btn_prev = slider_elem.querySelector('.controls .prev');
    const btn_next = slider_elem.querySelector('.controls .next');
    let index_old = 0;
    let index_new;

    const getNodeindex = (elm) => {
        return [...elm.parentNode.children].findIndex(c => c == elm);
    };

    const updateSlide = () => {
        index_new = getNodeindex(carousel.querySelector('.cloud9--active'));

        if (index_old === index_new) return;

        const old_slide = slider_elem.querySelector(`.cloud9-item:nth-child(${index_old + 1})`);
        const new_slide = slider_elem.querySelector(`.cloud9-item:nth-child(${index_new + 1})`);

        requestAnimationFrame(() => {
            new_slide.querySelector('.img:nth-child(6)').classList.remove('active');
            new_slide.querySelector('.img:nth-child(5)').classList.add('active');
            old_slide.querySelector('.img:nth-child(1)').classList.remove('active');
            old_slide.querySelector('.img:nth-child(2)').classList.add('active');
        });
        setTimeout(() => {
            requestAnimationFrame(() => {
                new_slide.querySelector('.img:nth-child(5)').classList.remove('active');
                new_slide.querySelector('.img:nth-child(4)').classList.add('active');
                old_slide.querySelector('.img:nth-child(2)').classList.remove('active');
                old_slide.querySelector('.img:nth-child(3)').classList.add('active');
            });
            setTimeout(() => {
                requestAnimationFrame(() => {
                    new_slide.querySelector('.img:nth-child(4)').classList.remove('active');
                    new_slide.querySelector('.img:nth-child(3)').classList.add('active');
                    old_slide.querySelector('.img:nth-child(3)').classList.remove('active');
                    old_slide.querySelector('.img:nth-child(4)').classList.add('active');
                });
                setTimeout(() => {
                    requestAnimationFrame(() => {
                        new_slide.querySelector('.img:nth-child(3)').classList.remove('active');
                        new_slide.querySelector('.img:nth-child(2)').classList.add('active');
                        old_slide.querySelector('.img:nth-child(4)').classList.remove('active');
                        old_slide.querySelector('.img:nth-child(5)').classList.add('active');
                    });
                    setTimeout(() => {
                        requestAnimationFrame(() => {
                            new_slide.querySelector('.img:nth-child(2)').classList.remove('active');
                            new_slide.querySelector('.img:nth-child(1)').classList.add('active');
                            old_slide.querySelector('.img:nth-child(5)').classList.remove('active');
                            old_slide.querySelector('.img:nth-child(6)').classList.add('active');
                        });
                    }, 50);
                }, 50);
            }, 50);
        }, 50);

        const text = new_slide.getAttribute('data-text');
        slider_elem.querySelector('.text').textContent = text;

        index_old = getNodeindex(carousel.querySelector('.cloud9--active'));
    };

    const options = {
        buttonLeft: $(btn_prev),
        buttonRight: $(btn_next),
        bringToFront: true,
        farScale: .2,
        yOrigin: $(carousel).height() / (window.innerWidth < 750 ? 9 : 5),
        yRadius: $(carousel).height() / (window.innerWidth < 750 ? 7 : 4),
        frontItemClass: 'cloud9--active',
    };

    $(carousel).Cloud9Carousel(options);

    window.addEventListener('resize', debounce(() => {
        const yOrigin = $('section.vision-circular-carousel .carousel').height() / (window.innerWidth < 750 ? 9 : 5);
        const yRadius = $('section.vision-circular-carousel .carousel').height() / (window.innerWidth < 750 ? 7 : 4);
        if (options.yOrigin === yOrigin || options.yRadius === yRadius) return;
        options.yOrigin = yOrigin;
        options.yRadius = yRadius;
        $(carousel).Cloud9Carousel(options);
    }, 200));

    btn_prev.addEventListener('click', () => {
        setTimeout(() => updateSlide(), 300);
    });

    btn_next.addEventListener('click', () => {
        setTimeout(() => updateSlide(), 300);
    });

    for (const item of cloud9_items) {
        item.addEventListener('click', () => {
            if (item.classList.contains('cloud9--active')) modalFrame(item);
            else setTimeout(() => updateSlide(), 300);
        });
    }
}

async function leasingLevels() {
    const map_elem = document.querySelector('section.leasing-levels');

    if (!map_elem) return;

    const map_wrap = map_elem.querySelector('.map-wrap');
    const floors = map_elem.querySelectorAll('.map .floor');
    const legend = map_elem.querySelector('.legend');
    const imgs = map_elem.querySelectorAll('.imgs > div');
    const legend_clone = legend.cloneNode(true);
    const legend_wrap = document.createElement('div');

    legend_wrap.classList.add('legend-wrap', 'container');
    legend.parentNode.insertBefore(legend_wrap, legend);
    legend_wrap.appendChild(legend);
    legend_clone.classList.add('clone');
    map_elem.querySelector('.map-wrap > .container:not(.legend-wrap) > div').appendChild(legend_clone);

    const keys = document.querySelectorAll('.legend-wrap .key');
    const toggle_btn = document.querySelector('.legend-wrap label.toggle');

    const stickyLegend = () => {
        if (checkMobileSize()) return;

        const wrap_offset = map_wrap.getBoundingClientRect().top;
        const wrap_height = map_wrap.offsetHeight;
        const legend_height = legend.offsetHeight;
        const edge_offset = 40;

        if (wrap_offset + wrap_height - legend_height - edge_offset <= 0) {
            legend_wrap.classList.remove('fixed');
            legend_wrap.classList.add('bottom');
        } else {
            if (wrap_offset <= edge_offset) {
                legend_wrap.classList.remove('bottom');
                legend_wrap.classList.add('fixed');
            } else {
                legend_wrap.classList.remove('fixed');
                legend_wrap.classList.remove('bottom');
            }
        }
    };

    const calcWidth = () => {
        const legend_width = legend_clone.offsetWidth;
        legend.style.width = `${legend_width}px`;
    };

    stickyLegend();
    calcWidth();

    window.addEventListener('scroll', throttle(() => {
        stickyLegend();
    }, 10));

    window.addEventListener('resize', debounce(() => {
        stickyLegend();
        calcWidth();
        updateMarkerPosition();
    }, 200));

    const updateMarkerPosition = () => {
        const markers = document.querySelectorAll('.tour-icon');
        const mobile = window.innerWidth < 750;

        if (
            (markers[0].getAttribute('data-position') === 'mobile' && mobile)
            || (markers[0].getAttribute('data-position') === 'desktop' && !mobile)
        ) return;

        markers.forEach(e => {
            const position = e.getAttribute('data-position');
            let xChange = 2;
            let yChange = 10;

            if (mobile && (!position || position === 'desktop')) {
                e.setAttribute('data-position', 'mobile');
                xChange = -xChange;
                yChange = -yChange;
            } else if (!mobile && position === 'mobile') {
                e.setAttribute('data-position', 'desktop');
            } else return;

            e.style.top = `${parseInt(e.style.top) + yChange}%`;
            e.style.left = `${parseInt(e.style.left) + xChange}%`;
        });
    };

    updateMarkerPosition();

    const checkActiveFloors = () => {
        return map_elem.querySelectorAll('.map .floor.active').length;
    };

    const checkActiveImages = () => {
        const total_images_per_floor = 5;
        if (map_elem.querySelectorAll('.imgs > div.active').length === (floors.length * total_images_per_floor)) {
            toggle_btn.querySelector('input + div span').textContent = 'clear all';
            toggle_btn.querySelector('input').checked = true;
        } else {
            toggle_btn.querySelector('input + div span').textContent = 'select all';
            toggle_btn.querySelector('input').checked = false;
        }
    };

    const floorUnfocus = () => {
        map_elem.classList.remove('hover');
        for (const floor of floors) {
            floor.classList.remove('active');
        }
        for (const img of imgs) {
            img.classList.remove('hover');
        }
    };

    const floorFocus = (settings) => {
        if (settings.action === 'mouseenter') {
            settings.floor.classList.add('active');
            for (const img of settings.floor.querySelectorAll('.imgs > div')) {
                img.classList.add('hover');
            }
            map_elem.classList.add('hover');
        } else if (settings.action === 'mouseleave') {
            settings.floor.classList.remove('active');
            for (const img of settings.floor.querySelectorAll('.imgs > div')) {
                img.classList.remove('hover');
            }
            if (checkActiveFloors() === 0) {
                map_elem.classList.remove('hover');
            }
        } else if (settings.action === 'touchend') {
            if (settings.floor.classList.contains('active')) {
                settings.floor.classList.remove('active');
                for (const img of settings.floor.querySelectorAll('.imgs > div')) {
                    img.classList.remove('hover');
                }
                map_elem.classList.remove('hover');
            } else {
                settings.floor.classList.add('active');
                for (const floor of getSiblings(settings.floor)) {
                    floor.classList.remove('active');
                    for (const img of floor.querySelectorAll('.imgs > div')) {
                        img.classList.remove('hover');
                    }
                }
                for (const img of settings.floor.querySelectorAll('.imgs > div')) {
                    img.classList.add('hover');
                }
                map_elem.classList.add('hover');
            }
        }
    };

    const toggleKey = (settings) => {
        if (settings.action === 'pointerenter') {
            for (const img of imgs) {
                if (img.getAttribute('data-category') === settings.key.getAttribute('data-category')) {
                    img.classList.add('hover');
                }
            }
        } else if (settings.action === 'pointerleave') {
            if (settings.key.classList.contains('active')) return;
            for (const img of imgs) {
                if (img.getAttribute('data-category') === settings.key.getAttribute('data-category') && !img.closest('.floor').classList.contains('active')) {
                    img.classList.remove('hover');
                }
            }
        } else if (settings.action === 'click') {
            if (settings.key.classList.contains('active')) {
                settings.key.classList.remove('active');
                for (const img of imgs) {
                    if (img.getAttribute('data-category') === settings.key.getAttribute('data-category')) {
                        img.classList.remove('active');
                    }
                }
            } else {
                settings.key.classList.add('active');
                for (const img of imgs) {
                    if (img.getAttribute('data-category') === settings.key.getAttribute('data-category')) {
                        img.classList.add('active');
                    }
                }
            }
            checkActiveImages();
            floorUnfocus();
        }
    };

    const toggle_state = (state) => {
        if (state === 'all') {
            toggle_btn.querySelector('input + div span').textContent = 'clear all';
            for (const key of keys) {
                key.classList.add('active');
            }
            for (const img of imgs) {
                img.classList.add('active');
            }
        } else if (state === 'none') {
            toggle_btn.querySelector('input + div span').textContent = 'select all';
            for (const key of keys) {
                key.classList.remove('active');
            }
            for (const img of imgs) {
                img.classList.remove('active', 'hover');
            }
        }
    };

    // Level map listeners

    let is_touchmoved = false;

    for (const floor of floors) {
        floor.addEventListener('mouseenter', () => {
            floorFocus({
                'floor': floor,
                'action': 'mouseenter',
            });
        });

        floor.addEventListener('mouseleave', () => {
            floorFocus({
                'floor': floor,
                'action': 'mouseleave',
            });
        });

        floor.addEventListener('touchstart', () => {
            is_touchmoved = false;
        });

        floor.addEventListener('touchmove', () => {
            is_touchmoved = true;
        });

        floor.addEventListener('touchend', () => {
            if (is_touchmoved !== true) {
                floorFocus({
                    'floor': floor,
                    'action': 'touchend',
                });
            }
        });
    }

    // Level key listeners

    for (const key of keys) {
        key.addEventListener('pointerenter', () => {
            toggleKey({
                'key': key,
                'action': 'pointerenter',
            });
        });

        key.addEventListener('pointerleave', () => {
            toggleKey({
                'key': key,
                'action': 'pointerleave',
            });
        });

        key.addEventListener('click', () => {
            toggleKey({
                'key': key,
                'action': 'click',
            });
        });
    }

    // toggle all keys listener

    toggle_btn.querySelector('input').addEventListener('change', (e) => {
        e.preventDefault();
        if (toggle_btn.querySelector('input:checked')) {
            toggle_state('all');
        } else {
            toggle_state('none');
        }
        floorUnfocus();
    });
}

async function polarizeGrid() {
    const section = document.querySelector('section.polarize-grid');

    if (!section) return;

    const hover_items = section.querySelector('.hover-items');
    const canvas_wrap = section.querySelector('.canvas');
    const canvas = section.querySelector('#field');

    // Ticker

    const ticker = section.querySelector('.ticker');
    const ticker_inner = section.querySelector('.ticker .wrap');
    const clone_ticker_inner = ticker_inner.cloneNode(true);
    ticker.append(clone_ticker_inner);
    ticker.append(clone_ticker_inner);
    ticker.classList.add('active');

    for (const span of ticker.querySelectorAll('.wrap > span')) {
        span.addEventListener('mouseenter', () => {
            ticker.classList.add('hover');
            span.classList.add('hover');
        });

        span.addEventListener('mouseleave', () => {
            ticker.classList.remove('hover');
            span.classList.remove('hover');
        });
    }

    // Hover items

    const hover_item_btns = section.querySelectorAll('.item .btn');

    const video_playback = (settings) => {
        if (!settings.player) return;

        if (checkMobile()) return;

        const item = settings.player.element.closest('.item');

        if (item.classList.contains('open')) {
            settings.player.play();
            // settings.player.play().then(() => {}).catch(error => console.warn(error));
            return;
        }

        if (settings.state === 'play') {
            settings.player.play();
            // settings.player.play().then(() => {}).catch(error => console.warn(error));
        } else if (settings.state === 'pause') {
            settings.player.pause();
            // settings.player.pause().then(() => {}).catch(error => console.warn(error));
        }
    };

    let hover_flag = false;
    let player;

    hover_items.addEventListener('pointerenter', (e) => {
        const target = document.elementFromPoint(e.clientX, e.clientY);

        if (target.classList.contains('btn')) {
            if (hover_flag) return;

            target.parentNode.classList.add('hover');

            video_playback({
                'player': player,
                'state': 'play'
            });
        }
    });

    hover_items.addEventListener('pointermove', (e) => {
        const target = document.elementFromPoint(e.clientX, e.clientY);

        if (target.classList.contains('btn')) {
            if (hover_flag) return;

            target.parentNode.classList.add('hover');

            video_playback({
                'player': player,
                'state': 'play'
            });
        }
    });

    hover_items.addEventListener('pointerleave', (e) => {
        const target = document.elementFromPoint(e.clientX, e.clientY);

        if (target.classList.contains('btn')) {
            if (target.parentNode.querySelector('.close')) {
                hover_flag = true;
            }

            if (hover_flag) return;

            target.parentNode.classList.remove('hover');

            video_playback({
                'player': player,
                'state': 'pause'
            });
        }
    });






    for (const item of hover_item_btns) {
        const close = item.parentNode.querySelector('.close');
        const video = item.parentNode.querySelector('.video');
        const play_full = item.parentNode.querySelector('.play-full');

        if (video) {
            player = new Player(video, {
                url: video.getAttribute('data-video-url'),
                autopause: false,
                title: false,
                byline: false,
                loop: true,
                muted: true,
                controls: false,
                color: '#ffffff',
            });

            video_playback({
                'player': player,
                'state': 'play'
            });

            if (play_full) {
                play_full.addEventListener('click', () => {
                    modalFrame(play_full);
                });
            }
        }

        if (close) {
            close.addEventListener('click', () => {
                hover_flag = true;

                item.parentNode.classList.remove('hover','open');

                video_playback({
                    'player': player,
                    'state': 'pause'
                });

                setTimeout(() => {
                    hover_flag = false;
                }, 2000);
            });
        }
    }

    // Ion field

    if (!checkMobileSize()) {
        AttachIonField({
            'section': section,
            'hover_items': hover_items,
            'ticker': ticker,
            'canvas_wrap': canvas_wrap,
            'canvas': canvas
        });
    }
}

async function selectListeners() {
    const select_btn = document.querySelectorAll('.btn--select');

    if (!select_btn) return;

    for (const btn of select_btn) {
        btn.querySelector('.select-container--trigger').addEventListener('click', () => {
            btn.classList.toggle('open');
        });
    }
}

async function quickNavs() {
    const page_selector = document.querySelector('.quick-navs');

    if (!page_selector) return;

    const select = page_selector.querySelector('.btn--select');
    const btn = page_selector.querySelector('a.btn');

    const updateLink = (option) => {
        const url = option.getAttribute('data-url');
        btn.setAttribute('href', url);
        if (url.charAt(0) === '/') {
            window.location = url;
        } else {
            window.open(url, '_blank', 'noopener, noreferrer');
        }
    };

    for (const option of select.querySelectorAll('.select-option')) {
        option.addEventListener('click', () => updateLink(option));
    }
}

async function filterSelect() {
    const page_selector = document.querySelector('.in-the-news');

    if (!page_selector) return;

    const select = page_selector.querySelector('.btn--select');

    const updateFilter = (option) => {
        const category = option.getAttribute('data-category');
        const url = new URL(document.location), params = url.searchParams;

        if (!category) {
            params.delete('c');
        } else if (option.classList.contains('selected')) {
            params.delete('c', category);
        } else {
            params.set('c', category);
        }

        url.hash = page_selector.id;
        window.location.href = url;
    };

    for (const option of select.querySelectorAll('.select-option')) {
        option.addEventListener('click', () => updateFilter(option));
    }
}

const addtocalendar = async () => {
    $('.calendar').click(() => $('.dropdown').slideToggle(200));
};

const captcha = async id => {
    // id cannot contain '-', there will be errors about the action if using an invalid character
    const $form = $(`#${id}`);
    if (!$form.length) return;

    $form.find('input[type="submit"], button').click(e => {
        e.preventDefault();
        grecaptcha.execute(process.env.RECAPTCHA_V3_KEY, { action: id }).then(token => {
            $form.find('input[name="recaptcha_v3"]').val(token);
            if ($form[0].reportValidity()) {
                const f = $form[0];
                f.__proto__.submit.apply(f);
            }
        });
    });
};

const newsletter = async id => {
    const $newsletter = $(`#${id}`);
    if (!$newsletter) return;

    $newsletter.submit(e => {
        e.preventDefault();
        const data = {};
        $newsletter.serializeArray().forEach(e => data[e.name] = e.value);

        $.post('/newsletter/', data, () => {
            $newsletter.find('input[type=email]').val('');
            $newsletter.find('.confirmation').show();
        });
    });
};

const AttachIonField = (settings) => {
    const canvas_offset = 83;
    const ticker_height = settings.ticker.offsetHeight / 2;
    const pattern_row_height = 36;
    const canvas_height = Math.round((window.innerHeight - canvas_offset - ticker_height) / pattern_row_height) * pattern_row_height;

    settings.canvas_wrap.style.height = `${canvas_height}px`;

    const app = new PIXI.Application({
        antialias: false,
        backgroundColor: 0x224149,
        resizeTo: settings.canvas,
        resolution: 2,
        autoDensity: true
    });

    settings.canvas.appendChild(app.view);

    let plusSprites = [];
    let minusSprites = [];

    function RespawnSprites() {
        while (minusSprites.length) {
            let sprite = minusSprites.pop();
            app.stage.removeChild(sprite);
        }
        while (plusSprites.length) {
            let sprite = plusSprites.pop();
            app.stage.removeChild(sprite);
        }

        let divRect = settings.canvas.getBoundingClientRect();
        const SPRITE_SPACING = 33;
        const SPRITE_OFFSET = 8;
        let widthMax = divRect.width / SPRITE_SPACING;
        let heightMax = divRect.height / SPRITE_SPACING;

        for (let i = 0; i < widthMax; i++) {
            for (let j = 0; j < heightMax; j++) {
                let shape;
                if ((i + j) & 1) {
                    shape = app.stage.addChild(PIXI.Sprite.from('../static/images/pattern-plus@2x.png'));
                    plusSprites.push(shape);
                } else {
                    shape = app.stage.addChild(PIXI.Sprite.from('../static/images/pattern-minus@2x.png'));
                    minusSprites.push(shape);
                }
                //shape.scale.set(.5);
                //shape.anchor.set(.5);
                shape._tx = shape._x = shape.x = (SPRITE_SPACING / 2 + SPRITE_SPACING * i) - SPRITE_OFFSET;
                shape._ty = shape._y = shape.y = SPRITE_SPACING / 2 + SPRITE_SPACING * j;
                shape.vel = [0, 0];
            }
        }
    }

    settings.hover_items.insertAdjacentHTML('beforeend', '<div class="pointer"></div>');
    const pointer = settings.hover_items.querySelector('.pointer');

    const cursorMove = (e) => {
        const { offsetX, offsetY } = onMousemove(e, settings.hover_items, settings.section);

        if (e.target.getAttribute('aria-label') == 'Close' || e.target.getAttribute('aria-label') == 'Play Video') {
            if (pointer.classList.contains('active')) {
                pointer.classList.remove('active');
            }
        } else {
            if (!pointer.classList.contains('active')) {
                pointer.classList.add('active');
            }
        }

        pointer.style.transform = `translate3d(${offsetX}px,${offsetY}px,0)`;

        for (let shape of minusSprites) {
            let xdiff = shape._x - offsetX;
            let ydiff = shape._y - offsetY;
            let inputAngle = Math.atan2(ydiff, xdiff);
            let distanceFromOriginPosition = Math.sqrt(Math.pow(shape._x - offsetX, 2) + Math.pow(shape._y - offsetY, 2));
            let distanceFromCurrentPosition = Math.sqrt(Math.pow(shape.x - offsetX, 2) + Math.pow(shape.y - offsetY, 2));
            if (distanceFromOriginPosition < 80) {
                shape._tx = Math.cos(inputAngle) * - distanceFromOriginPosition * distanceFromCurrentPosition * .0176 + shape._x;
                shape._ty = Math.sin(inputAngle) * - distanceFromOriginPosition * distanceFromCurrentPosition * .0176 + shape._y;
            } else {
                shape._tx = shape._x;
                shape._ty = shape._y;
            }
        }

        for (let shape of plusSprites) {
            let xdiff = shape._x - offsetX;
            let ydiff = shape._y - offsetY;
            let inputAngle = Math.atan2(ydiff, xdiff);
            let distanceFromOriginPosition = Math.sqrt(Math.pow(shape._x - offsetX, 2) + Math.pow(shape._y - offsetY, 2));
            //let distanceFromCurrentPosition = Math.sqrt(Math.pow(shape.x - offsetX, 2) + Math.pow(shape.y - offsetY, 2));
            if (distanceFromOriginPosition < 65) {
                shape._tx = Math.cos(inputAngle) * 50 + shape._x;
                shape._ty = Math.sin(inputAngle) * 50 + shape._y;
            } else {
                shape._tx = shape._x;
                shape._ty = shape._y;
            }
        }
    };

    const cursorStop = (e) => {
        pointer.classList.remove('active');

        for (let shape of minusSprites) {
            shape._tx = shape._x;
            shape._ty = shape._y;
        }

        for (let shape of plusSprites) {
            shape._tx = shape._x;
            shape._ty = shape._y;
        }
    };

    let multifinger_touch = false;

    settings.hover_items.addEventListener('mousemove', (e) => {
        cursorMove(e);
    });

    settings.hover_items.addEventListener('mouseleave', (e) => {
        cursorStop(e);
    });

    settings.hover_items.addEventListener('touchstart', (e) => {
        if (e.touches.length > 1) {
            multifinger_touch = true;
        } else {
            multifinger_touch = false;
        }
    });

    settings.hover_items.addEventListener('touchmove', (e) => {
        if (!multifinger_touch) return;

        e.preventDefault();
        cursorMove(e);
    });

    settings.hover_items.addEventListener('touchend', (e) => {
        if (!multifinger_touch) return;

        cursorStop(e);
    });

    app.ticker.add(dt => {
        dt *= 2;
        for (let shape of plusSprites) {
            // Velocity damping
            shape.vel[0] *= .65;
            shape.vel[1] *= .65;

            // Add homing velocity
            shape.vel[0] += (shape._tx - shape.x) * 0.01;
            shape.vel[1] += (shape._ty - shape.y) * 0.01;

            shape.x += shape.vel[0] * dt;
            shape.y += shape.vel[1] * dt;
        }

        for (let shape of minusSprites) {
            // Velocity damping
            shape.vel[0] *= .75;
            shape.vel[1] *= .75;

            // Add homing velocity
            shape.vel[0] += (shape._tx - shape.x) * 0.01;
            shape.vel[1] += (shape._ty - shape.y) * 0.01;

            shape.x += shape.vel[0] * dt;
            shape.y += shape.vel[1] * dt;
        }
    });

    RespawnSprites();
    addEventListener('resize', () => RespawnSprites());
};

async function loadMore() {
    const article_load = document.querySelector('.article-load');
    const pr_load = document.querySelector('.pr-load');
    if (!article_load && !pr_load) return;

    let articlePage = 1,
        prPage = 1;

    const loadArticles = () => {
        articlePage++;
        $.ajax({
            url: `/ajax/articles/?a_page=${articlePage}`,
            type: 'GET',
            success: data => {
                if (data.length > 0) {
                    for (const a of data) {
                        let listItem = `
                            <a class="news-list-item" href="/news/${a.slug}">
                                <div class="headline-and-link">
                                    <h2 class="text--medium">
                                        ${a.title}
                                    </h2>
                                    <div class="link text--medium">
                                        <div class="btn btn--arrow-angle btn--red">Read More</div>
                                    </div>
                                </div>

                                <div class="text--mono">${a.date}</div>
                                <div class="text--medium body">${a.byline}</div>

                                <div class="link text--medium">
                                    <div class="btn btn--mobile btn--arrow-angle btn--red">Read More</div>
                                </div>
                            </a>
                        `;

                        $('.news-grid').append(listItem);

                        if (a.isLast) $('.article-load').hide();
                    }
                } else {
                    $('.article-load').hide();
                }
            }
        });
    };

    const loadPressReleases = () => {
        prPage++;
        $.ajax({
            url: `/ajax/pr/?pr_page=${prPage}`,
            type: 'GET',
            success: data => {
                if (data.length > 0) {
                    for (const pr of data) {
                        let listItem = `
                            <a href="${pr.file}" style="text-decoration: none;" target="_blank">
                                <div class="press-release text--white">
                                    <div class="img lazyload" data-bgset="${pr.image}"></div>
                                    <div class="ion-logo"></div>
                                    <div class="text--mono">${pr.date}</div>
                                    <h2 class="text--medium">${pr.info}</h2>
                                    <a class="btn btn--arrow-angle btn--red" href="${pr.file}" target="_blank"></a>
                                </div>
                            </a>
                        `;

                        $('.pr-grid').append(listItem);

                        if (pr.isLast) $('.pr-load').hide();
                    }
                } else {
                    $('.pr-load').hide();
                }
            }
        });
    };

    if (article_load) article_load.addEventListener('click', () => loadArticles());
    if (pr_load) pr_load.addEventListener('click', () => loadPressReleases());
}

async function threeImageParallax() {
    const section = document.querySelector('section.three-image');

    if (!section) return;

    const img_1 = section.querySelector('.img-1');
    const img_2 = section.querySelector('.img-2');
    const img_3 = section.querySelector('.img-3');

    const parallaxAnimation = () => {
        const scroll_top = document.documentElement.scrollTop || document.body.scrollTop;
        const section_height = section.offsetHeight;
        const offset_top_1 = img_1.getBoundingClientRect().top - scroll_top + section_height;
        const offset_top_2 = img_2.getBoundingClientRect().top - scroll_top + section_height;
        const offset_top_3 = img_3.getBoundingClientRect().top - scroll_top + section_height;

        if (offset_top_1 < 0) {
            img_1.style.transform = `translate3d(0,-${(offset_top_1 * -1) / 16}px,0)`;
        }
        if (offset_top_2 < 0) {
            img_2.style.transform = `translate3d(0,-${(offset_top_2 * -1) / 8}px,0)`;
        }
        if (offset_top_3 < 0) {
            img_3.style.transform = `translate3d(0,-${(offset_top_3 * -1) / 6}px,0)`;
        }
    };

    parallaxAnimation();

    window.addEventListener('scroll', throttle(() => {
        parallaxAnimation();
    }, 10));
}

const isInViewport = (elem) => {
    const bounding = elem.getBoundingClientRect();
    return (
        bounding.top >= 0 &&
        bounding.left >= 0 &&
        bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
};

async function smoothScrollButton() {
    const button = document.querySelector('.btn--leasing'),
        floatingButton = document.querySelector('.btn--leasing-float'),
        leasingForm = document.querySelector('#leasing_inquiry');

    if (!button || !floatingButton || !leasingForm) return;

    const scrollToForm = (e) => {
        e.preventDefault();
        let hash = button.getAttribute('href');
        hash = hash.substring(hash.indexOf('#'));
        new MoveTo({
            tolerance: 0,
            duration: 400,
            easing: 'easeInOutQuad',
            container: window
        }, easeFunctions).move(document.querySelector(hash));
    };

    button.addEventListener('click', scrollToForm);
    floatingButton.addEventListener('click', scrollToForm);

    if (!isInViewport(button) && !isInViewport(leasingForm)) {
        floatingButton.classList.add('active');
    }

    window.addEventListener('scroll', (e) => {
        if (!isInViewport(button) && !isInViewport(leasingForm)) {
            floatingButton.classList.add('active');
        } else {
            floatingButton.classList.remove('active');
        }
    });
}

async function customScroll() {
    const eventList = document.querySelector('.event-list');

    if (!eventList) return;

    eventList.parentNode.querySelector('.btn--arrow-up').addEventListener('click', () => {
        new MoveTo({
            tolerance: 0,
            duration: 400,
            easing: 'easeInOutQuad',
            container: eventList
        }, easeFunctions).move(-eventList.offsetHeight);
    });

    eventList.parentNode.querySelector('.btn--arrow-down').addEventListener('click', () => {
        new MoveTo({
            tolerance: 0,
            duration: 400,
            easing: 'easeInOutQuad',
            container: eventList
        }, easeFunctions).move(eventList.offsetHeight);
    });
}

const rotateImages = async () => {
    const rotate = (list) => {
        for (let i = 0; i < list.length; i++) {
            if (list[i].classList.contains('active')) {
                const next = i === list.length - 1 ? 0 : i + 1;
                list[i].classList.remove('active');
                list[next].classList.add('active');
                return;
            }
        }
    };
    const rotateOuter = () => {
        if (hover.outer && !active.outer) {
            rotate(outerImages);
            active.outer = true;
            setTimeout(() => {
                active.outer = false;
                rotateOuter();
            }, freq);
        }
    };
    const rotateInner = () => {
        if (hover.inner && !active.inner) {
            rotate(innerImages);
            active.inner = true;
            setTimeout(() => {
                active.inner = false;
                rotateInner();
            }, freq);
        }
    };
    const outerImages = document.querySelectorAll('[class*="img-outer-"]');
    const innerImages = document.querySelectorAll('[class*="img-inner-"]');
    const outerContainer = document.querySelector('.double-image-rollover');
    const innerContainer = document.querySelector('.double-image-rollover .inner');
    const freq = 500;
    const active = { inner: false, outer: false };
    const hover = { inner: false, outer: false };

    if (!innerContainer || !outerContainer) return;

    outerContainer.addEventListener('mouseenter', () => {
        hover.outer = true;
        rotateOuter();
    });
    outerContainer.addEventListener('mouseleave', () => {
        hover.outer = false;
        hover.inner = false;
    });
    innerContainer.addEventListener('mouseenter', () => {
        hover.inner = true;
        hover.outer = false;
        rotateInner();
    });
    innerContainer.addEventListener('mouseleave', () => {
        hover.outer = true;
        hover.inner = false;
        rotateOuter();
    });
};

async function animateMap() {
    const map_arrows = document.querySelector('#animated-map .arrows');

    if (!map_arrows) return;

    lottie.loadAnimation({
        container: map_arrows,
        renderer: 'svg',
        loop: false,
        autoplay: false,
        path: '/static/js/Ion-SMID-map.json'
    });

    const options = {
        root: null,
        rootMargin: '0% 0% -70% 0%',
        threshold: 0
    };

    const handleIntersect = (entries, observer) => {
        for (const entry of entries) {
            if (entry.isIntersecting) {
                setTimeout(() => {

                    lottie.play();

                    observer.unobserve(entry.target);
                }, 1);
            }

        }
    };

    const observer = new IntersectionObserver(handleIntersect, options);

    observer.observe(map_arrows);
}

async function validateForms() {
    const leasingForm = document.querySelector('#leasing_inquiry');

    if (!leasingForm) return;

    const select_options = leasingForm.querySelectorAll('select option');
    const btn_select = leasingForm.querySelector('.btn--select');

    for (const [index, option] of select_options.entries()) {
        if (index === 0) {
            btn_select.querySelector('.select-container--trigger').innerText = option.innerText;
        } else {
            btn_select.querySelector('.select-options').insertAdjacentHTML('beforeend',`<div class="select-option" data-value="${option.getAttribute('value')}">${option.innerText}</div>`);
        }
    }

    for (const [index, option] of btn_select.querySelectorAll('.select-option').entries()) {
        option.addEventListener('click', () => {
            btn_select.querySelector('.select-container--trigger').innerText = option.innerText;
            leasingForm.querySelector(`select option[value="${option.getAttribute('data-value')}"]`).selected = true;
            btn_select.classList.remove('open');
            btn_select.classList.add('active');
        });
    }
}

const readyState = setInterval(() => {
    if (document.readyState === 'complete') {
        clearInterval(readyState);
        selectListeners();
        renderModalFrame();
        renderCountUp();
        scrollFadeIn();
        pageNav();
        rotateImages();
        carouselImages();
        carouselImageOverlay();
        carouselBlock();
        carouselSolid();
        visionTimeline();
        vision3dCarousel();
        visionProgramSlider();
        leasingLevels();
        quickNavs();
        addtocalendar();
        newsletter('newsletter');
        newsletter('home-newsletter');
        newsletter('nav-newsletter');
        // captcha('contact_form');
        // captcha('leasing_inquiry');
        filterSelect();
        loadMore();
        threeImageParallax();
        smoothScrollButton();
        customScroll();
        polarizeGrid();
        animateMap();
        validateForms();
    }
}, 100);
